/*
* Molecule: List view item
*/

.list-view-item {
  display: block;
  text-decoration: none;
  color: var(--color-text);
  margin-bottom: 32px;

  &:hover,
  &:focus {
    filter: brightness(1);

    .list-view-item__image {
      > img {
        transform: scale(1.1);
      }
    }

    .list-view-item-content__title {
      color: var(--color-link);
    }

    .list-view-item-content__cta {
      color: var(--color-link);

      > svg {
        margin-left: 32px;
      }
    }
  }

  &__image {
    overflow: hidden;

    @media (--viewport-sm-min) {
      max-width: 320px;
    }

    > img {
      width: 100%;
      transition: transform 0.3s var(--transition);
    }
  }

  &-content {
    background: #fff;
    position: relative;
    margin-top: -32px;
    margin-left: 32px;
    padding: 16px;

    @media (--viewport-sm-min) {
      max-width: 320px;
    }
    @media (--viewport-md-min) {
      width: calc(100% - 32px);
    }

    &__category {
      display: block;
      font-weight: 600;
      font-size: 0.625rem; /* 10px */
      text-transform: uppercase;
      letter-spacing: 1.5px;
      margin-bottom: 4px;
    }

    &__date {
      display: block;
      margin-bottom: 4px;
    }

    &__title {
      display: block;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-size: 1.5rem; /* 24px */
      line-height: 1.75rem; /* 28px */
      margin-bottom: 10px;
      transition: color 0.3s var(--transition);
    }

    &__excerpt {
      display: block;
      margin-bottom: 48px;
    }

    &__cta {
      display: flex;
      font-family: var(--navigation-font-family);
      font-size: 0.6875rem; /* 11px */
      line-height: 1;
      text-transform: uppercase;
      transition: color 0.3s var(--transition);

      svg {
        margin-left: 24px;
        transition: fill 0.3s var(--transition), margin 0.3s var(--transition);
        fill: currentColor;
      }
    }
  }
}
