/*
 * Organism: Breadcrumb
 */

.breadcrumb {
  margin: 20px 0;

  @media (--viewport-md-min) {
    margin: 40px 0;
  }

  &-list {
    list-style: none;
    display: flex;
    align-self: center;
    margin: 0;
    padding: 0;
  }

  &-item {
    display: none;
    margin-bottom: 0;

    &:before {
      display: none;
    }

    &:nth-last-child(-n + 2) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (--viewport-sm-min) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__link,
    &__page {
      display: block;
      font-size: 0.625rem; /* 10px */
      text-transform: uppercase;
      letter-spacing: 1.5px;
      line-height: 1;
    }

    &__link {
      text-decoration: none;
      color: currentColor;

      @media (--viewport-md-min) {
        &:hover {
          color: var(--color-link);
        }
      }
    }

    ~ .breadcrumb-item {
      &:before {
        content: '-';
        display: block;
        font-size: 0.625rem; /* 10px */
        letter-spacing: 1.5px;
        margin: 0 8px;
        text-decoration: none;
        transform: none;
        width: auto;
        height: auto;
        position: static;
      }

      @media (--viewport-ms-max) {
        &:nth-last-child(2) {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
