/*
 * Atom: Input 
 */

.input {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  font-size: 1rem; /* 16px */
  line-height: 1;
  font-family: var(--base-font-family);
  color: var(--color-text);
  background-color: #fff;
  background-image: none;
  transition: all 0.3s var(--transition);

  /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214 */
  border: 1px solid var(--color-border);
  vertical-align: bottom;
  appearance: none;

  @media (--viewport-md-min) {
    height: 56px;
  }

  &:active,
  &:focus {
    border-color: var(--color-link);
  }

  @media (--viewport-md-min) {
    &:hover {
      border-color: var(--color-link);
    }
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color-input-placeholder);
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color-input-placeholder);
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--color-input-placeholder);
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--color-input-placeholder);
  }

  &__description {
    font-size: 0.875rem; /* 14px */
    color: var(--color-input-placeholder);
    font-style: italic;
    margin-top: 4px;

    &[type="text"],
    &[type="search"],
    &[type="email"],
    &[type="password"],
    &[type="url"],
    &[type="tel"],
    &[type="number"] {
      -webkit-border-radius: 0;
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

/* clears the 'X' from Internet Explorer */
input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
