/*
 * Atom: Button
*/

.button,
a.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-height: 48px;
  padding: 12px 16px;
  color: var(--color-primary);
  font-weight: 400;
  font-family: var(--navigation-font-family);
  font-size: 1rem; /* 16px */
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid var(--color-primary);
  background-color: var(--site-background-color);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s var(--transition);

  @media (--viewport-ms-min) {
    min-width: 240px;
  }

  &:active,
  &:focus {
    filter: brightness(1);
    border-color: var(--color-link);
  }

  &:active {
    background: var(--color-link);
    border-color: var(--color-link);
    color: #fff;
  }

  > svg {
    margin-left: 16px;
    fill: currentColor;
    flex-shrink: 0;

    @media (--viewport-sm-max) {
      max-width: 24px;
    }
  }

  @media (--viewport-md-min) {
    min-height: 64px;
    padding: 12px 24px;

    &:hover {
      filter: brightness(1);
      background: var(--color-link);
      color: #fff;
      border-color: var(--color-link);
    }
  }

  &--ghost {
    background-color: transparent;
  }

  &--chip {
    width: auto;
    min-width: 40px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 0;
    padding-top: 0;
    min-height: 23px;
    border-width: 1px;
    font-weight: normal;
    font-size: .75rem; /* 12px */
    text-transform: capitalize;
  }

  &--solid {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}
