/*
 * Organism: Hero
 */

.hero {
  padding-top: 40px;
  margin-bottom: 40px;

  &--image {
    min-height: 344px;

    @media (--viewport-sm-min) {
      min-height: 600px;
    }

    .hero-category {
      &:before {
        height: 344px;

        @media (--viewport-sm-min) {
          height: 600px;
        }
      }

      &-line {
        display: block;
        position: absolute;
        top: 232px;
        left: -24px;
        width: 1px;
        height: 680px;
        background: var(--hero-line-color);
        transform-origin: center;

        @media (--viewport-sm-min) {
          height: 448px;
          left: 64px;
        }

        @media (--viewport-md-min) {
          height: 680px;
          left: 240px;
        }
      }
    }

    /* Drag first RTE up on top of hero, if the hero has the image class */
    ~ .grid {
      .share-page {
        top: -56px;
        margin-bottom: 0;

        @media (--viewport-sm-min) {
          top: 0;
        }
      }

      .rte--first {
        position: relative;
        z-index: 2;
        background: var(--site-background-color);
        margin-top: -104px;
        padding: 64px 32px 0 32px;

        @media (--viewport-sm-min) {
          padding: 32px 32px 0 0;
          margin-top: -344px;
        }

        @media (--viewport-md-min) {
          background: none;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 10%;
            background: var(--site-background-color);
            z-index: -1;
            width: 90%;
            height: 100%;
          }
        }

        h2 {
          @media (--viewport-sm-min) {
            margin-left: -64px;
          }

          @media (--viewport-md-min) {
            margin-left: -88px;
          }
        }
      }
    }
  }

  &-category {
    position: relative;

    @media (--viewport-ms-max) {
      width: calc(100% - 8.33333333%);
      padding: 0 32px;
    }

    @media (--viewport-sm-min) {
      margin-top: 48px;
    }

    &-block {
      display: block;
      position: absolute;
      top: 96px;
      left: calc(-8.33333333% - 41px);
      background: var(--color-accent);
      width: 184px;
      height: 184px;
      transform-origin: right bottom;

      @media (--viewport-sm-min) {
        left: 0;
        top: 24px;
        width: 440px;
        height: 440px;
      }
    }

    &__label {
      display: block;
      font-weight: 600;
      font-size: 0.625rem; /* 10px */
      text-transform: uppercase;
      margin-bottom: 24px;

      @media (--viewport-sm-min) {
        margin-bottom: 0;
        margin-left: 16px;
      }
    }
  }

  &-content {
    position: relative;
    z-index: 1;

    @media (--viewport-ms-max) {
      width: calc(100% - 8.33333333%);
      padding: 0 32px;
    }

    &__title {
      margin-bottom: 16px;

      @media (--viewport-sm-min) {
        margin-bottom: 32px;
      }
    }

    &__description {
      font-size: 1.3125rem; /* 21px */
    }

    &__date {
      display: block;
      font-family: var(--navigation-font-family);
      font-size: 0.875rem; /* 14px */
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: 32px;
    }
  }

  &__image {
    position: relative;
    z-index: 1;
    margin-top: 32px;

    > img {
      width: 100%;
    }

    @media (--viewport-md-min) {
      margin-top: 40px;
    }

    @media (--viewport-ms-max) {
      width: calc(100% + 8.33333333%);
      height: 0;
      padding-bottom: calc(100% + 8.33333333%);

      > img {
        object-fit: cover;
      }
    }
  }
}
