/*
 * Molecule: Mobile menu toggle
 */

.mobile-menu-toggle {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  @media (--viewport-ms-min) {
    position: relative;
    top: 4px;
  }

  &--active {
    .mobile-menu-toggle-box__lines {
      transform: translate3d(0, 6px, 0) rotate(45deg);

      &:before {
        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
        opacity: 0;
      }

      &:after {
        transform: translate3d(0, -12px, 0) rotate(-90deg);
      }
    }
  }

  &-box {
    position: relative;
    width: 20px;
    height: 12px;

    &__lines {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background: var(--color-primary);
      border-radius: 1px;
      transition: transform 0.3s var(--transition);

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: var(--color-primary);
        border-radius: 1px;
        transition: all 0.3s var(--transition);
      }

      &:before {
        top: 6px;
      }

      &:after {
        top: 12px;
      }
    }
  }

  @media (--viewport-md-min) {
    display: none;
  }
}
