/*
 * Atom: Icon
 */

.icon {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  fill: currentColor;
  user-select: none;
}
