/*
 * Page: Landing page
 */

.landing-page-hero {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100vw;
  margin-bottom: 64px;

  @media (--viewport-sm-min) {
    min-height: 94vh;
  }

  &-overlay {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  &-content {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    height: 100%;

    &__title {
      margin-bottom: 40px;

      @media (--viewport-md-min) {
        margin-top: 80px;
      }

      @media (--viewport-lg-min) {
        margin-top: 120px;
      }

      img {
        flex-shrink: 0;
        flex-grow: 0;
        max-width: 100%;
        height: auto;
        display: block;
        margin-bottom: 16px;
      }
    }

    p {
      max-width: 560px;

      @media (--viewport-sm-min) {
        font-size: 1.3125rem; /* 21px */
      }
    }
  }
}
