/*
 * Molecule: Grid element - Appetiser
 */

.appetiser {
  padding-bottom: 160px;
  margin: 0 calc(0 - var(--half-gutter-width)) 64px;
  width: calc(100% + (2 * var(--half-gutter-width)));

  @media (--viewport-sm-min) {
    margin: 0 0 64px;
    width: 100%;
    padding: 104px 0 0;
  }

  &-wrapper {
    position: relative;
    min-height: 260px;

    @media (--viewport-sm-min) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -24px;
        left: 0;
        width: calc(100% - 16.66666667%);
        height: calc(100% + 48px);
        background: transparent;
        border: 1px solid var(--color-accent-2);
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: var(--color-accent-2);
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;

      @media (--viewport-sm-min) {
        height: 100%;
        top: -104px;
        width: 66.66666667%;
        left: 33.33333333%;
      }

      @media (--viewport-md-min) {
        width: 50%;
        left: 41.66666667%;
      }
    }
  }

  &-content {
    position: relative;
    z-index: 2;
    padding: 32px;
    margin-left: calc(var(--half-gutter-width) / 2);
    margin-right: calc(var(--half-gutter-width) / 2);

    @media (--viewport-ms-max) {
      top: 160px;
    }

    @media (--viewport-sm-min) {
      margin: 0;
    }

    &__title {
      display: block;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-size: 1.875rem; /* 30px */
      line-height: 2rem; /* 32px */
      margin-bottom: 16px;

      @media (--viewport-sm-min) {
        font-size: 2.25rem; /* 36px */
        line-height: 2.375rem; /* 38px */
        margin-bottom: 32px;
        margin-left: -40px;
      }

      @media (--viewport-md-min) {
        font-size: 3rem; /* 48px */
        line-height: 3.125rem; /* 50px */
      }
    }

    &__category {
      display: block;
      font-weight: 600;
      font-size: 0.625rem; /* 10px */
      text-transform: uppercase;
      letter-spacing: 1.5px;
      margin-bottom: 4px;

      @media (--viewport-sm-min) {
        margin-left: -40px;
      }
    }

    &__description {
      @media (--viewport-sm-min) {
        font-size: 1.3125rem; /* 21px */
      }
    }
  }

  &__image {
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;

    @media (--viewport-sm-min) {
      height: 100%;
      top: -104px;
      width: 66.66666667%;
      left: 33.33333333%;
    }

    @media (--viewport-md-min) {
      width: 50%;
      left: 41.66666667%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  /* Modifiers */

  &--image {
    .appetiser-content {
      background: #fff;
    }
  }

  @media (--viewport-sm-min) {
    &--image-left {
      .appetiser-wrapper {
        &:before {
          left: 0;
        }

        &:after {
          left: 24px;
          width: calc(100% - 16.66666667% - 24px);
        }
      }

      .appetiser__image {
        left: 0;
      }

      .appetiser-content {
        &__title {
          margin-left: 0;
        }
      }
    }
  }
}
