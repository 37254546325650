/*
 * Organism: Related posts
 */
.related-posts {
  margin-top: 64px;

  &-header {
    text-align: center;

    &__title {
      display: block;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-size: 1.875rem; /* 30px */
      line-height: 2rem; /* 32px */
      margin-bottom: 32px;
      position: relative;
      z-index: 2;

      @media (--viewport-sm-min) {
        font-size: 2.25rem; /* 36px */
        line-height: 2.375rem; /* 38px */
        margin-bottom: 48px;
      }

      @media (--viewport-md-min) {
        font-size: 3rem; /* 48px */
        line-height: 3.125rem; /* 50px */
      }
    }
  }

  &-wrapper {
    position: relative;

    &:before {
      content: '';
      display: block;
      background: var(--related-posts-bg);
      position: absolute;
      top: 120px;
      left: 0;
      width: 100%;
      height: calc(100% - 120px);
    }
  }
}
