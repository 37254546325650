/*
 * Organism: Mobile menu
 */

.mobile-menu {
  width: 100vw;
  position: fixed;
  top: 64px;
  left: 0;
  background: #fff;
  z-index: 300;
  tranform: translate3d(-100px, 0, 0);
  opacity: 0;
  transition: transform 0.3s var(--transition), opacity 0.3s var(--transition);
  pointer-events: none;

  &--active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
  }

  @media (--viewport-md-min) {
    display: none;
  }

  &-find-physician {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1;

    & .icon {
      stroke: var(--color-primary);
      height: 34px;
      min-height: 34px;
      width: 34px;
      min-width: 34px;
      margin-right: 16px;
    }
  }

  &__inner {
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-y: scroll;

    @media (--viewport-ms-min) {
      padding-left: 52px;
      padding-right: 52px;
    }
  }
}

.mobile-menu-search {
  position: relative;
  margin-bottom: 48px;

  &-input {
    border: 0;
    border-bottom: 1px solid var(--color-primary);
  }

  &-submit {
    min-width: auto;
    border: 0;
    background: none;
    position: absolute;
    top: 4px;
    right: 0;
  }
}

.mobile-menu-navigation {
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
      cursor: pointer;
      margin-bottom: 32px;
      position: relative;

      &:before {
        display: none;
      }

      &--active,
      &:focus {
        color: var(--color-primary);
      }

      &--active {
        .mobile-menu-navigation-list-item__dropdown > .icon {
          transform: rotate(-180deg);
        }
      }

      &__dropdown {
        width: 32px;
        position: absolute;
        padding: 6px 0;
        top: 6px;
        right: 0;
        text-align: right;

        > .icon {
          min-width: 12px;
          min-height: 12px;
          width: 12px;
          height: 12px;
          transition: transform 0.3s var(--transition);
        }
      }

      &__link {
        display: inline;
        font-family: var(--heading-font-family);
        font-size: 1.5rem; /* 24px */
        text-decoration: none;
        position: relative;
        color: currentColor;

        &:after {
          content: '';
          position: absolute;
          width: 48px;
          height: 1px;
          background-color: var(--color-primary);
          left: -26px;
          bottom: 0;

          @media (--viewport-ms-min) {
            width: 88px;
            left: -62px;
          }
        }
      }

      &-sublist {
        list-style: none;
        margin: 0;
        padding: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s var(--transition);

        &-item {
          cursor: pointer;
          position: relative;
          color: var(--color-text);

          &:before {
            display: none;
          }

          &--active,
          &:focus {
            color: var(--color-primary);
          }

          &__link {
            display: block;
            padding: 8px 16px;
            font-family: var(--navigation-font-family);
            font-weight: bold;
            font-size: 1rem; /* 16px */
            text-decoration: none;
            position: relative;
            color: currentcolor;
          }

          &-list {
            list-style: none;
            margin: 0;
            padding: 0;

            &-item {
              cursor: pointer;
              position: relative;
              color: var(--color-text);
              margin: 0;

              &:before {
                display: none;
              }

              &--active,
              &:focus {
                color: var(--color-primary);
              }

              &__link {
                display: block;
                padding: 8px 16px;
                font-family: var(--navigation-font-family);
                font-size: 0.8125rem; /* 13px */
                text-decoration: none;
                position: relative;
                color: currentcolor;
              }
            }
          }
        }
      }
    }
  }
}
