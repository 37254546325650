.b2b-contact-form {
  &__title {
    margin-top: 48px;
    margin-bottom: 24px;
    position: relative;
    z-index: 2;
  }

  &__subtitle {
    max-width: 720px;
    font-weight: 400;
    font-size: 1.125rem; /* 18px */
    line-height: 1.25rem; /* 20px */
    margin-bottom: 20px;

    @media (--viewport-md-min) {
      font-size: 1.25rem; /* 20px */
      line-height: 1.75rem; /* 28px */
      margin-bottom: 48px;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    .checkbox {
      .checkbox__tick:before {
        border-color: inherit;
      }
    }

    .form {
      width: 100%;

      @media (--viewport-sm-min) {
        width: 560px;
      }

      .input__wrapper {
        width: 100%;
        margin-bottom: 20px;
      }


      button {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      .select__wrapper {
        width: 100%;

        .icon.select__icon {
          top: 48px;
          transform: unset;
          fill: var(--color-text);
        }

        select {
          height: 57px;
          padding: 0 16px;
          color: var(--color-text);
          font-size: var(--input-font-size);
          background-color: #fff;
          border: 1px solid var(--color-border);
          border-radius: var(--input-border-radius);
          width: 100%;
          display: inline-block;
          font-size: 16px;
        }
      }
    }
  }
}
