/**
 * Base: Typography
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  margin-top: 0;
  margin-bottom: 16px;
}

h1 {
  font-size: 2.375rem; /* 38px */
  line-height: 2.25rem; /* 36px */

  @media (--viewport-sm-min) {
    font-size: 3.25rem; /* 52px */
    line-height: 3.125rem; /* 50px */
  }

  @media (--viewport-md-min) {
    font-size: 4.25rem; /* 68px */
    line-height: 4.125rem; /* 66px */
  }
}

h2 {
  font-size: 1.875rem; /* 30px */
  line-height: 2rem; /* 32px */

  @media (--viewport-sm-min) {
    font-size: 2.25rem; /* 36px */
    line-height: 2.375rem; /* 38px */
  }

  @media (--viewport-md-min) {
    font-size: 3rem; /* 48px */
    line-height: 3.125rem; /* 50px */
    margin-bottom: 32px;
  }
}

h3 {
  font-size: 1.375rem; /* 22px */
  line-height: 1.75rem; /* 28px */

  @media (--viewport-sm-min) {
    font-size: 1.5rem; /* 24px */
    line-height: 1.75rem; /* 28px */
  }

  @media (--viewport-md-min) {
    font-size: 1.75rem; /* 28px */
    line-height: 1.875rem; /* 30px */
  }
}

h4 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.25rem; /* 20px */
  margin-top: 32px;
}

p {
  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewport-sm-min) {
    font-size: 1.125rem; /* 18px */
  }
}

/* Bold */
b,
strong {
  font-weight: 600;
}

ul,
ol {
  list-style: none;
  margin: 0 0 24px;
  padding: 0 0 0 24px;

  li {
    @media (--viewport-sm-min) {
      font-size: 1.125rem; /* 18px */
    }
  }
}

ol {
  counter-reset: li;

  > li {
    counter-increment: li;

    &:before {
      content: counter(li) ".";
      position: absolute;
      left: -24px;
      color: var(--color-primary);
    }
  }
}

ul {
  > li:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#FFA183' viewBox='0 0 24 24'%3E%3Cpath d='M12.1309091,4.26656862 C14.7687273,1.32365107 20.3083636,-7.34788079e-16 24,-7.34788079e-16 C18.9818182,3.73269602 14.5047273,18.1340197 12.1309091,24 L11.8726021,23.3527845 C9.42622345,17.1546102 4.84210526,3.85929434 0,-7.34788079e-16 C4.3911253,0.0722376829 8.64335403,1.56779136 12.1309091,4.26656862 Z'%3E%3C/path%3E%3C/svg%3E");
    transform: rotate(-90deg);
    width: 12px;
    height: 12px;
    position: absolute;
    top: 8px;
    left: -32px;
  }
}

li {
  position: relative;
  margin-bottom: 16px;
}
