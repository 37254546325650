/*
 * Molecule: Navigation
 */

.navigation {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  /* To trigger fixed position to stay within this block, we set a value on the transform property */
  transform: translateX(0);

  @media (--viewport-sm-max) {
    display: none;
  }

  &-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &-item {
      margin: 0 16px;
      display: inline-flex;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;
      transition: color 0.3s var(--transition);
      position: relative;

      &:before {
        display: none;
      }

      &--active,
      &:hover,
      &:focus {
        color: var(--color-primary);

        .navigation-list-item__link {
          &:after {
            width: 24px;
          }
        }
      }

      &:hover,
      &:focus {
        > .icon {
          transform: rotate(-180deg);
        }

        .navigation-list-item-dropdown {
          opacity: 1;
          pointer-events: auto;
        }
      }

      > .icon {
        min-width: 6px;
        min-height: 6px;
        width: 6px;
        height: 6px;
        margin-left: 4px;
        transition: transform 0.3s var(--transition);
      }

      &__link {
        display: block;
        font-family: var(--navigation-font-family);
        font-size: 0.8125rem; /* 13px */
        text-transform: uppercase;
        text-decoration: none;
        color: currentColor;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          background-color: currentColor;
          transition: width 0.3s var(--transition);
          left: -16px;
          bottom: -2px;
        }
      }

      &-dropdown {
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 100%;
        left: 0;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        padding: 20px 0;
        transition: opacity 0.3s var(--transition);

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          left: 0px;
          right: 0px;
          top: auto;
          width: auto;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: -2000px;
          width: 10000px;
          height: 100%;
          z-index: -1;
          background: #fff;
          box-shadow: 0 16px 16px rgba(0, 0, 0, 0.08);
        }

        &-group {
          width: calc((100% / 3) - 40px);
          margin: 20px;

          &__link {
            display: block;
            font-size: 1.125rem; /* 18px */
            font-family: var(--navigation-font-family);
            font-weight: bold;
            color: var(--color-text);
            margin-bottom: 16px;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          &-list {
            list-style: none;
            margin: 0;
            padding: 0;

            &-item {
              &:before {
                display: none;
              }

              &__link {
                font-family: var(--navigation-font-family);
                font-size: 0.875rem; /* 14px */
                text-decoration: none;
                color: var(--color-text);

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        &__link {
          font-weight: bold;
          color: green;
        }

        &-sublist {
          margin: 0;
          padding: 0;
          background: blue;
        }
      }
    }
  }
}
