/*
 * Molecule: Search overlay toggle
 */

.search-overlay-toggle {
  display: none;

  @media (--viewport-md-min) {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 24px;
    min-width: 24px;
    height: 24px;

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 17px;
      height: 17px;
      transform: translate3d(-50%, -50%, 0);
      transform-origin: top left;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        border: 1px solid currentColor;
        box-shadow: 0 0 1px 0px currentColor inset, 0 0 1px 0px currentColor;
        border-radius: 100%;
        transition: all 0.3s var(--transition);
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 1px;
        background: currentColor;
        box-shadow: 0 0 1px 0px currentColor inset, 0 0 1px 0px currentColor;
        border-radius: 100%;
        transform: rotate(45deg);
        transform-origin: bottom right;
        bottom: 0;
        right: 0;
        transition: all 0.3s var(--transition);
      }

      &__cross {
        display: block;
        position: absolute;
        width: 0%;
        height: 1px;
        background: currentColor;
        box-shadow: 0 0 1px 0px currentColor inset, 0 0 1px 0px currentColor;
        border-radius: 100%;
        transform: rotate(-45deg);
        transform-origin: top right;
        top: 0;
        right: 0;
        opacity: 0;
        transition: all 0.3s var(--transition);
      }
    }

    &:hover {
      .search-overlay-toggle-icon {
        &:before {
          border-color: var(--color-primary);
          box-shadow: 0 0 1px 0px var(--color-primary) inset,
            0 0 1px 0px var(--color-primary);
        }
        &:after {
          background: var(--color-primary);
          box-shadow: 0 0 1px 0px var(--color-primary) inset,
            0 0 1px 0px var(--color-primary);
        }

        &__cross {
          background: var(--color-primary);
          box-shadow: 0 0 1px 0px var(--color-primary) inset,
            0 0 1px 0px var(--color-primary);
        }
      }
    }

    &--active {
      .search-overlay-toggle-icon {
        &:before {
          opacity: 0;
        }

        &:after {
          width: 100%;
          bottom: 4px;
          right: 4px;
        }

        &__cross {
          opacity: 1;
          width: 100%;
          right: 4px;
        }
      }
    }
  }
}
