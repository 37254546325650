.content-card {
    text-decoration: none;
}
.content-card-inner {
    position: relative;
}
.content-card-inner__image {
    height: 200px;
    width: 100%;
}
.content-card-inner__image img {
    object-fit: contain;
    width: 100%;
}
.content-card-inner-content {
    margin-top: -2rem;
    margin-left: 2rem;
    padding: 1rem;
    background: #fff;
    color: #000;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}
.content-card-inner-content__title {
    font-weight: bold;
}
.content-card-inner-content__button {
    text-decoration: underline;
    display: flex;
    justify-content: space-between;
    align-items: center;
}