/*
 * Molecule: Grid element - FAQ
 */

.faq {
  margin-bottom: 94px;

  @media (--viewport-sm-min) {
    margin-bottom: 105px;
  }

  &-content {
    position: relative;

    &__title {
      display: block;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-size: 1.875rem; /* 30px */
      line-height: 2rem; /* 32px */
      margin-bottom: 16px;
      position: relative;
      z-index: 2;

      @media (--viewport-sm-min) {
        font-size: 2.25rem; /* 36px */
        line-height: 2.375rem; /* 38px */
        margin-bottom: 32px;
        max-width: 560px;
      }

      @media (--viewport-md-min) {
        font-size: 3rem; /* 48px */
        line-height: 3.125rem; /* 50px */
      }
    }

    &__description {
      position: relative;
      z-index: 2;
      margin-bottom: 24px;

      @media (--viewport-sm-min) {
        margin-bottom: 48px;
      }
    }
  }

  &-list {
    position: relative;
    z-index: 2;
    transition: all 0.3s var(--transition);

    &-item {
      background: var(--site-background-color);

      &:last-child {
        .faq-list-item-header {
          border-bottom: 1px solid var(--color-border);
        }
      }

      &--active {
        &:last-child {
          .faq-list-item-header {
            border-bottom: 0;
          }
          .faq-list-item-content__inner {
            border-bottom: 1px solid var(--color-border);
          }
        }

        .faq-list-item-header {
          &:before,
          &:after {
            border-bottom-color: var(--color-link);
          }

          &:before {
            transform: translate3d(0, -50%, 0) rotate(135deg);
          }

          &:after {
            transform: translate3d(0, -50%, 0) rotate(45deg);
          }
        }
      }

      &-header {
        position: relative;
        border: 1px solid var(--color-border);
        border-bottom: 0;
        padding: 16px 48px 16px 16px;
        cursor: pointer;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 16px;
          width: 16px;
          height: 1px;
          border-bottom: 1px solid var(--color-border);
          transform: translate3d(0, -50%, 0) rotate(90deg);
          transition: all 0.3s var(--transition);
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 16px;
          width: 16px;
          height: 1px;
          border-bottom: 1px solid var(--color-border);
          transform: translate3d(0, -50%, 0);
          transition: all 0.3s var(--transition);
        }

        &__label {
          display: block;
          user-select: none;
          cursor: pointer;
        }
      }

      &-content {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.3s var(--transition);

        &__inner {
          border: 1px solid var(--color-border);
          border-bottom: 0;
          padding: 16px 48px 16px 16px;
        }
      }
    }
  }

  &-cta {
    position: relative;
    z-index: 1;
    margin-top: 56px;
    text-align: center;

    @media (--viewport-sm-min) {
      margin-top: 343px;
      margin-right: 40px;
      text-align: left;
    }

    &:after {
      content: '';
      display: block;
      background: var(--color-accent-2);
      position: absolute;
      bottom: calc(
        (var(--gutter-compensation--mobile) - var(--half-gutter-width--mobile)) *
          2
      );
      right: calc(
        (var(--gutter-compensation--mobile) - var(--half-gutter-width--mobile)) *
          2
      );
      width: calc(
        100% + (var(--gutter-width--mobile) + var(--half-gutter-width--mobile)) *
          4
      );
      height: calc(
        100% + (var(--gutter-width--mobile) + var(--half-gutter-width--mobile)) *
          4
      );

      @media (--viewport-sm-min) {
        width: 480px;
        height: 560px;
      }

      @media (--viewport-md-min) {
        width: 560px;
      }

      @media (--viewport-lg-min) {
        width: 680px;
        height: 532px;
      }
    }

    &__inner {
      position: relative;
      z-index: 1;

      @media (--viewport-sm-min) {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }

    &__title {
      display: block;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-size: 1.375rem; /* 22px */
      line-height: 1.75rem; /* 28px */
      margin-bottom: 16px;

      @media (--viewport-sm-min) {
        font-size: 1.5rem; /* 24px */
        line-height: 1.75rem; /* 28px */
      }
    }

    &__button {
      @media (--viewport-sm-min) {
        width: 100%;
        min-width: auto !important;
      }
    }
  }
}
