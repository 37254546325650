/*
 * Molecule: Grid element - Image
 */

.image {
  position: relative;
  margin: 32px calc(0 - var(--half-gutter-width)) 48px;
  width: calc(100% + (2 * var(--half-gutter-width)));

  @media (--viewport-sm-min) {
    width: 100%;
    margin: 32px 0;
  }

  @media (--viewport-md-min) {
    margin: 64px 0;
  }

  img {
    width: 100%;
  }

  &:before {
    content: "";
    display: block;
    background: var(--color-accent-2);
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(100% - (2 * var(--half-gutter-width)));
    height: 120%;

    @media (--viewport-sm-min) {
      left: -120px;
      transform: translate3d(0, -50%, 0);
      width: 200px;
      height: 80%;
    }
  }
}
