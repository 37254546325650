/*
 * Page: Clinic finder page
 */

.clinic-finder-page-heading {
  margin-top: 16px;
  
  @media (--viewport-sm-min) {
    margin-top: 32px;
  }
}

.clinic-finder-embed {
  &__iframe {
    display: block;
    width: 100vw;
    height: 70vh;

    @media (--viewport-md-min) {
      height: 90vh;
    }
  }

  + section {
    margin-top: 32px;
    @media (--viewport-md-min) {
      margin-top: 48px;
    }
  }
}
