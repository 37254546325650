/*
 * Atom: Select
 */

.select {
  @extend .input;
  cursor: pointer;
  line-height: 48px;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='#837872' viewBox='0 0 11 11'%3E%3Cpath d='M5.56,1.934 C6.769,0.6 9.308,-3.3677787e-16 11,-3.3677787e-16 C8.7,1.692 6.648,8.22 5.56,10.879 L5.35061018,10.3561022 C4.20913958,7.46026814 2.16060606,1.70312121 0,-3.3677787e-16 C2.0125991,0.0327447397 3.96153726,0.710666756 5.56,1.934 Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  background-size: 12px;
  padding-right: 48px;

  @media (--viewport-md-min) {
    line-height: 56px;
  }

  &::-ms-expand {
    display: none;
  }

  option {
    font-weight: normal;
  }
}
