/*
 * Molecule: Logo
 */

.logo {
  display: block;

  @media (--viewport-sm-max) {
    flex: 1;
  }

  &__image {
    display: block;
    margin: 0 auto;
    height: 48px;
    max-width: 90%;

    @media (--viewport-md-min) {
      height: 60px;
      max-width: 100%;
    }
  }
}
