/**
 * Base
 */

/* Box sizing */
* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

/* HTML */
html {
  overflow-x: hidden;
  width: 100%;
}

/* Body */
body {
  background-color: var(--site-background-color);
  font-family: var(--base-font-family);
  font-weight: var(--base-font-weight);
  font-size: var(--base-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  line-height: 1.5;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;

  /* Add vertical lines */
  @media (--viewport-sm-min) {
    /* background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 25%,
      var(--color-border-light) 25%,
      rgba(255, 255, 255, 0) 25.1%,
      rgba(255, 255, 255, 0) 50%,
      var(--color-border-light) 50%,
      rgba(255, 255, 255, 0) 50.1%,
      rgba(255, 255, 255, 0) 75%,
      var(--color-border-light) 75%,
      rgba(255, 255, 255, 0) 75.1%,
      rgba(255, 255, 255, 0) 100%
    ); */
  }
}

/* Site */
.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* Main */
main {
  flex-grow: 1;
  min-height: 560px;
  position: relative;
}

/* Grid */
.grid {
  position: relative;
  padding-bottom: 40px;

  .grid-two-columns-left {
    display: flex;
    justify-content: flex-end;

    @media (--viewport-ms-max) {
      margin-bottom: 16px;
    }
  }

  .grid-two-columns-right {
    display: flex;

    @media (--viewport-ms-max) {
      margin-bottom: 16px;
    }
  }
}

/* Links */
a {
  color: var(--color-link);
  text-decoration: underline;
  text-decoration-skip-ink: auto;

  &:hover,
  &:focus {
    filter: brightness(0.9);
  }
}

/* Horizontal ruler */
hr {
  border: none;
  border-bottom: 1px solid var(--color-light);
  margin: 32px 0;
}

/* Image handling */
figure {
  margin: 0;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    transition: opacity 0.3s;
  }

  figcaption {
    margin-top: 8px;
  }

  &.contain-width img {
    width: auto;
  }
}


/* Oversteer cookiebot popup */
#CybotCookiebotDialogBodyContentTitle {
  display: none !important;
}
#CybotCookiebotDialog {
  top: auto !important;
  bottom: 0 !important;
}

.show-only-desktop {
@media (--viewport-sm-max) {
  display: none !important;
}
}

.show-only-mobile {
@media (--viewport-md-min) {
  display: none !important;
}
}

/* margin top spacing */
.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

/* margin bottom spacing */
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}
