/*
 * Organism: Search overlay
 */

.search-overlay {
  display: none;

  @media (--viewport-md-min) {
    display: flex;
    position: fixed;
    background: #fff;
    transition: all 0.3s var(--transition);
    pointer-events: none;
    opacity: 0;
    z-index: 300;
    width: 100%;
    left: 0;
    top: 145px;

    &--active {
      pointer-events: auto;
      opacity: 1;
    }

    > .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;
    }

    &-field {
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 16px 0 0;
      border-bottom: 1px solid var(--color-primary);
      margin-bottom: 24px;
    }

    &-submit {
      min-width: auto;
      border: 0;
      background: none;
    }

    &-input {
      border: 0;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--color-input-placeholder);
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--color-input-placeholder);
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--color-input-placeholder);
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--color-input-placeholder);
      }
    }
  }
}
