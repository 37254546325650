.xs-hidden {
    @media (--viewport-xs-max) {
        display: none;
    }
}

.ms-hidden {
    @media (--viewport-ms-min) and (--viewport-ms-max) {
        display: none;
    }
}

.sm-hidden {
    @media (--viewport-sm-min) and (--viewport-sm-max) {
        display: none;
    }
}

.md-hidden {
    @media (--viewport-md-min) and (--viewport-md-max) {
        display: none;
    }
}

.lg-hidden {
    @media (--viewport-lg-min) and (--viewport-lg-max) {
        display: none;
    }
}

.xl-hidden {
    @media (--viewport-xl-min) {
        display: none;
    }
}
