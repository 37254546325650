/*
 * Organism: Header
 */

.header {
  position: fixed;
  z-index: 190;
  width: 100%;
  left: 0;
  top: 0;
  background: #fff;
  line-height: 1;
  padding-top: 8px;
  padding-bottom: 8px;

  @media (--viewport-sm-max) {
    height: 64px;
    z-index: 300;
  }

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (--viewport-md-min) {
    padding-top: 30px;

    > .container {
      display: block;
    }
  }

  &--sticky-hidden {
    transform: translate3d(0, -105%, 0);

    .navigation-list-item:hover {
      .navigation-list-item-dropdown {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &__inner {
    @media (--viewport-md-min) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 30px;
    }
  }

  &-social-profiles {

    &__link {
      display: inline-flex;
      margin-right: 16px;
      color: currentColor;


      &:last-child {
        margin-right: 0;
      }

      > .icon {
        min-width: 16px;
        width: 16px;
        height: 16px;
      }
    }

    @media (--viewport-sm-max) {
      display: none;
    }
  }

  &-links {
    @media (--viewport-md-min) {
      display: flex;
      justify-content: flex-end;
    }

    &-clinic-finder {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      transition: color 0.3s var(--transition);

      @media (--viewport-ms-min) {
        position: relative;
        top: 4px;
      }

      @media (--viewport-md-min) {
        position: static;
        width: auto;
        height: auto;
        justify-content: flex-start;
        color: currentColor;
        margin-right: 60px;
        font-family: var(--navigation-font-family);
        font-size: 0.8125rem; /* 13px */
        text-decoration: none;
        text-transform: uppercase;
      }

      &:hover {
        color: var(--color-primary);
      }

      &__label {
        display: none;

        @media (--viewport-md-min) {
          display: block;
          flex-shrink: 0;
        }
      }

      > .icon {
        @media (--viewport-md-min) {
          margin-right: 4px;
        }
      }
    }
  }

  &-search {
    display: none;

    @media (--viewport-md-min) {
      display: flex;
      justify-content: flex-end;

      &--active {
        border-bottom: 1px solid var(--color-primary);

        .header-search-input {
          width: 240px;
        }
      }

      &-input {
        border: 0;
        width: 0;
        padding: 0;
        height: 24px;
        transition: width 0.3s var(--transition);
      }

      &-submit {
        &-button-container {
          display: flex;
          align-items: center;
        }

        &-button {
          height: 24px;
          min-width: auto;
          border: 0;
          padding: 0;
          background: none;
        }
      }
    }
  }
}

.header-spacer {
  height: 64px;

  @media (--viewport-md-min) {
    height: 160px;
  }
}
