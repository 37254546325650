/*
 * Organism: Hero
 */

.hero-homepage {
  margin-bottom: 40px;

  /* Top heading */
  &__title {
    margin: 30px 0 50px 0;
    text-align: center;

    font-family: var(--heading-font-family);
    font-size: 1.5rem; /* 24px */
    line-height: 1.75rem; /* 28px */

    text-transform: none;
  }

  /* Featured / Top Stories section names */
  &-area-title {
    display: block;
    margin: 24px 0 16px;

    font-family: var(--heading-font-family);
    font-size: 1.5rem; /* 24px */
  }

  /* Featured post column */
  &-featured {
    display: block;
    text-decoration: none;
    color: var(--color-text);
    position: relative;
    z-index: 1;

    &:hover,
    &:focus {
      filter: brightness(1);

      .hero-homepage-featured__image {
        > img {
          transform: scale(1.1);
        }
      }

      .hero-homepage-featured-content__title {
        color: var(--color-link);
      }
    }

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: -80px;
      width: 80%;
      height: 50%;
      background: var(--color-accent);

      @media (--viewport-md-min) {
        width: 60%;
      }
    }

    &__image {
      overflow: hidden;

      > img {
        width: 100%;
        transition: transform 0.3s var(--transition);
      }
    }

    &-content {
      position: relative;
      background: #fff;
      padding: 32px 40px;

      @media (--viewport-sm-min) {
        margin-left: 72px;
        margin-top: -40px;
      }

      &__category {
        display: block;
        font-weight: 600;
        font-size: 0.875rem; /* 14px */
        text-transform: uppercase;
        letter-spacing: 1.5px;
        margin-bottom: 4px;
      }

      &__title {
        display: block;
        font-family: var(--heading-font-family);
        font-weight: var(--heading-font-weight);
        font-size: 3rem; /* 48px */
        line-height: 1;
        margin-bottom: 16px;
        transition: color 0.3s var(--transition);
      }

      &__description {
        display: block;
        font-size: 1.125rem; /* 18px */
        margin-bottom: 16px;
      }
    }
  }
}
