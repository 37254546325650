/*
 * Atom: Checkbox
 */

.checkbox {
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    .checkbox__tick:before {
      border-color: var(--color-link);
    }
  }

  [type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:focus + .checkbox__tick:before {
      outline: #5d9dd5 solid 1px;
      box-shadow: 0 0 8px #5e9ed6;
    }

    &:checked + .checkbox__tick:before {
      border-color: var(--color-border);
    }

    &:disabled + .checkbox__tick:before {
      background: var(--color-disabled);
      border-color: var(--color-disabled);
    }

    &:checked + .checkbox__tick:after {
      opacity: 1;
    }
  }

  &__tick {
    position: relative;
    margin-right: 8px;

    &:before {
      content: "";
      display: block;
      height: 20px;
      width: 20px;
      border: 1px var(--color-border) solid;
      border-radius: 3px;
      transition: border 0.3s var(--transition);
    }

    &:after {
      content: "";
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#837872' viewBox='0 0 20 14' %3E%3Cpath d='M6.184,6.2 C8.784,4.861 11.817,1.867 19.931,0 C15.125,2.6 5.81,13.491 5.81,13.491 C5.81,13.491 2.462,6.223 0,2.147 C1.379,2.7 4.983,4.727 6.184,6.2 Z'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 50% top 50%;
      background-size: 20px;
      position: absolute;
      top: 50%;
      left: calc(50% + 4px);
      transform: translate3d(-50%, -50%, 0);
      height: 16px;
      width: 20px;
      opacity: 0;
      transition: opacity 0.3s var(--transition);
    }
  }
}
