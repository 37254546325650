/*
 * Organism: Footer
 */

.footer {
  background: var(--color-footer-bg);
  color: var(--color-footer-text);
  padding: 24px 0;
  overflow: hidden;
  @media (--viewport-sm-min) {
    padding: 48px 0;
  }

  @media (--viewport-md-min) {
    padding: 72px 0;
  }

  a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-social-profiles {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--viewport-sm-min) {
      align-items: flex-start;
    }

    @media (--viewport-md-min) {
      align-items: flex-end;
    }

    &__header {
      display: block;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-size: 1.75rem; /* 28px */
      line-height: 1.875rem; /* 30px */
      margin-top: 0;
      margin-bottom: 16px;
    }

    &-links {
      &__link {
        display: inline-flex;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
