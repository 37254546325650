/*
 * Molecule: Grid element - Clinic finder CTA
 */

.clinic-finder-cta {
  width: 100%;
  padding: 64px 0;
  margin-bottom: 64px;
  position: relative;

  @media (--viewport-sm-min) {
    padding: 120px 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -2000px;
    width: 10000px;
    height: 100%;
    z-index: 1;
    background: var(--clinic-finder-cta-bg);
  }

  &-content {
    position: relative;
    z-index: 3;
    max-width: 560px;

    &__title {
      display: block;
      font-family: var(--heading-font-family);
      font-weight: var(--heading-font-weight);
      font-size: 1.875rem; /* 30px */
      line-height: 2rem; /* 32px */
      margin-bottom: 16px;
      margin-left: -24px;

      @media (--viewport-sm-min) {
        font-size: 2.25rem; /* 36px */
        line-height: 2.375rem; /* 38px */
        margin-bottom: 32px;
        margin-left: -40px;
      }

      @media (--viewport-md-min) {
        font-size: 3rem; /* 48px */
        line-height: 3.125rem; /* 50px */
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
