/*
 * Molecule: Grid element - CTA
 */

.cta {
  position: relative;
  margin-bottom: 64px;

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}
