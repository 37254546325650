/*
 * Molecule: Grid element - Video
 */

.video {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  font-family: var(--heading-font-family);
  display: flex;
  width: 100%;
  padding-top: 60%;
  margin: 64px calc(0 - var(--half-gutter-width));
  width: calc(100% + (2 * var(--half-gutter-width)));

  &--inverted {
    color: var(--color-text);
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  @media (--viewport-sm-min) {
    max-height: 700px;
    margin: 64px 0;
    width: 100%;
  }

  &:before {
    content: '';
    display: block;
    background: var(--color-accent-2);
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(100% - (2 * var(--half-gutter-width)));
    height: 120%;

    @media (--viewport-sm-min) {
      left: -120px;
      transform: translate3d(0, -50%, 0);
      width: 200px;
      height: 80%;
    }
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__play-icon {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.3s;

    .icon {
      width: 36px;
      height: 36px;
      fill: currentColor;
      transform: rotate(-90deg);
    }
  }

  &__content {
    position: absolute;
    width: 90%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    margin-bottom: 60px;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  &__title {
    font-size: 1.875rem; /* 30px */
    line-height: 2rem; /* 32px */

    @media (--viewport-sm-min) {
      font-size: 2.25rem; /* 36px */
      line-height: 2.375rem; /* 38px */
    }

    @media (--viewport-md-min) {
      font-size: 3rem; /* 48px */
      line-height: 3.125rem; /* 50px */
    }
  }

  &__subtitle {
    font-size: 1.125rem; /* 18px */
    line-height: 1.25rem; /* 20px */
    margin-top: 20px;
  }

  &--hide-content {
    .video__content,
    .video__play-icon {
      opacity: 0;
    }
  }
}
