/*
 * Molecule: Share page
 */

.share-page {
  position: relative;
  z-index: 10;
  margin-bottom: 24px;

  @media (--viewport-sm-min) {
    position: absolute;
    top: 0;
    width: 40px;
    opacity: 0;
    transition: opacity 0.3s var(--transition);
    transform: translate3d(16px, 0, 0);
    margin-bottom: 0;

    &--active {
      opacity: 1;
    }
  }

  @media (--viewport-md-min) {
    width: 72px;
  }

  &__graphic {
    display: none;

    @media (--viewport-sm-min) {
      display: block;
      border: 1px solid color(var(--color-border) a(20%));
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    @media (--viewport-md-min) {
      width: 72px;
      height: 72px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      width: 1px;
      height: 40px;
      border-right: 1px solid color(var(--color-border) a(20%));
    }

    .icon {
      min-width: 20px;
      width: 20px;
      min-height: 20px;
      height: 20px;
      fill: var(--color-primary);

      @media (--viewport-md-min) {
        min-width: 32px;
        width: 32px;
        min-height: 32px;
        height: 32px;
      }
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    position: relative;
    left: 34px;

    @media (--viewport-sm-min) {
      top: 0;
      left: 0;
      flex-direction: column;
      margin-top: 72px;
      width: 40px;
    }

    @media (--viewport-md-min) {
      width: 72px;
    }

    &__button {
      display: block;
      text-decoration: none;
      color: var(--color-border);
      line-height: 1;
      margin: 0 8px;

      @media (--viewport-sm-min) {
        margin: 0 0 16px;
      }

      .icon {
        min-width: 16px;
        width: 16px;
        min-height: 16px;
        height: 16px;
        transition: all 0.3s var(--transition);

        @media (--viewport-md-min) {
          min-width: 20px;
          width: 20px;
          min-height: 20px;
          height: 20px;
        }
      }

      &:hover {
        color: var(--color-primary);
      }
    }
  }
}
