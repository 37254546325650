/*
 * Organism: Hero focus points
 */

.hero-focus-points {
  padding-top: 40px;
  background: var(--color-accent);
  position: relative;

  .container {
    position: relative;
  }

  &-content-wrapper {
    background: var(--site-background-color);
  }

  &-content {
    position: relative;
    width: 100%;
    background: #fff;
    z-index: 1;
    margin-top: -20%;
    margin-bottom: 32px;
    padding: 32px;

    @media (--viewport-lg-min) {
      padding-left: calc(16.66666667% + var(--half-gutter-compensation));
    }

    &__title {
      margin-bottom: 16px;
      font-size: 1.875rem; /* 30px */
      line-height: 2rem; /* 32px */

      @media (--viewport-md-min) {
        margin-bottom: 32px;
      }

      @media (--viewport-lg-min) {
        margin-left: -24px;
      }
    }

    &__description {
      font-size: 1.3125rem; /* 21px */
    }

    &__category {
      display: block;
      font-weight: 600;
      font-size: 0.625rem; /* 10px */
      text-transform: uppercase;

      @media (--viewport-lg-min) {
        margin-left: -24px;
      }
    }

    &__date {
      display: block;
      font-family: var(--navigation-font-family);
      font-size: 0.875rem; /* 14px */
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: 32px;
    }
  }

  &__image {
    display: block;
    width: 100%;
  }

  &-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__item {
      cursor: pointer;
      display: block;
      position: absolute;
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.3);
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 100%;
      width: 24px;
      height: 24px;
      transform: translate3d(-50%, -50%, 0);
      transform-origin: center;
      animation: pulseMobile 2.5s infinite;
      transition: background 0.3s var(--transition);

      @media (--viewport-sm-min) {
        width: 40px;
        height: 40px;
        animation: pulseDesktop 2.5s infinite;
      }

      &:hover {
        animation: none;
        background: rgba(255, 255, 255, 0.6);

        &:after {
          background: rgba(255, 255, 255, 0.6);
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        width: 8px;
        height: 8px;
        transition: background 0.3s var(--transition);

        @media (--viewport-sm-min) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &-overlay {
    &[v-cloak] {
      display: none;
    }

    position: fixed;
    z-index: 300;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    padding: 24px;
    overflow-y: scroll;

    @media (--viewport-sm-min) {
      overflow-y: visible;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      transform: translate3d(-50%, -50%, 0);
    }

    &__close {
      background: #fff;
      border: 0;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
      transition: all 0.3s var(--transition);
      cursor: pointer;

      @media (--viewport-sm-min) {
        right: -20px;
        top: -20px;
      }

      .icon {
        min-width: 16px;
        min-height: 16px;
        width: 16px;
        height: 16px;
        fill: currentColor;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      &:hover {
        background: var(--color-link);
        color: #fff;
      }
    }

    &-content {
      .before-after-slider {
        margin-bottom: 0;
      }

      &__title {
        display: block;
        margin-top: 16px;
        font-family: var(--heading-font-family);
        font-weight: var(--heading-font-weight);
        font-size: 1.3125rem; /* 21px */

        @media (--viewport-sm-min) {
          margin-left: 16px;
          max-width: 80%;
        }
      }

      &__text {
        display: block;
        margin-bottom: 16px;

        @media (--viewport-sm-min) {
          margin-left: 16px;
          max-width: 80%;
        }
      }

      &__cta {
        margin-bottom: 8px;

        @media (--viewport-sm-min) {
          margin-left: 16px;
        }
      }
    }

    &-fade-enter-active,
    &-fade-leave-active {
      transition: opacity 0.3s var(--transition);
    }
    /* .fade-leave-active below version 2.1.8 */
    &-fade-enter,
    &-fade-leave-to {
      opacity: 0;
    }
  }
}

@-webkit-keyframes pulseDesktop {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 56px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulseDesktop {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 56px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 56px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes pulseMobile {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 24px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulseMobile {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 24px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 24px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
