/*
 * Molecule: Grid element - RTE
 */

.rte {
  width: 100%;
  padding: 0 32px;
  margin-bottom: 40px;

  @media (--viewport-sm-min) {
    padding: 0;
  }
}
