/*
 * Base: Themes
 * This is used to apply classes to rows in the grid, which will define a theme for all grid elements in that row.
 * An example of this is to add a blue theme, and then the quote will have a different background.
 */

.theme {
  &-red {
    /* Grid elements */
    .image,
    .video,
    .clinic-finder-cta {
      &:before {
        background: var(--color-red);
      }
    }

    .quote {
      background: var(--color-red);
      color: #fff;

      &:before {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 149 111' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#d8b684' d='M33.118,110.26 C23.1553333,110.26 15.145,106.693333 9.087,99.56 C3.029,92.4266667 0,83.069 0,71.487 C0,56.409 4.44266667,42.3403333 13.328,29.281 C22.2133333,16.2216667 33.253,6.46133333 46.447,0 L48.87,4.847 C42.9529722,9.44418507 37.8940026,15.0498782 33.926,21.406 C29.618,28.1393333 26.5216667,36.4863333 24.637,46.447 L33.119,48.466 C42.5423333,50.622 49.8793333,54.5933333 55.13,60.38 C60.3329144,66.0422466 63.1536888,73.4917269 63.006,81.18 C63.006,89.796 60.1116667,96.796 54.323,102.18 C48.5860888,107.547221 40.9718948,110.448551 33.118,110.26 Z'%3E%3C/path%3E%3Cpath fill='#d8b684' d='M118.337,110.26 C108.374333,110.26 100.364,106.693333 94.306,99.56 C88.248,92.4266667 85.219,83.07 85.219,71.49 C85.219,56.412 89.6616667,42.3433333 98.547,29.284 C107.432333,16.2246667 118.472,6.46333333 131.666,0 L134.089,4.847 C128.171972,9.44418507 123.113003,15.0498782 119.145,21.406 C114.837,28.1393333 111.740667,36.4863333 109.856,46.447 L118.338,48.466 C127.762,50.622 135.099,54.5933333 140.349,60.38 C145.551914,66.0422466 148.372689,73.4917269 148.225,81.18 C148.225,89.796 145.330667,96.796 139.542,102.18 C133.805338,107.546976 126.191552,110.448287 118.338,110.26 L118.337,110.26 Z'%3E%3C/path%3E%3C/svg%3E");
        opacity: 0.75;
      }
    }

    .appetiser {
      &-wrapper {
        &:before {
          background: var(--color-red);
        }
        &:after {
          border-color: var(--color-red);
        }
      }
    }

    .faq {
      &-list,
      &-list-item-content__inner,
      &-list-item-header {
        border-color: var(--color-red);
      }

      &-cta {
        &__button {
          color: var(--color-pink);
          border-color: var(--color-pink);

          &:hover {
            background: var(--color-pink);
            border-color: var(--color-pink);
            color: var(--color-red);
          }
        }

        &:after {
          background: var(--color-red);
        }
      }
    }
  }

  &-earth {
    /* Grid elements */
    .image,
    .video,
    .clinic-finder-cta {
      &:before {
        background: var(--color-earth);
      }
    }

    .quote {
      background: var(--color-earth);
      color: #fff;

      &:before {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 149 111' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#d8b684' d='M33.118,110.26 C23.1553333,110.26 15.145,106.693333 9.087,99.56 C3.029,92.4266667 0,83.069 0,71.487 C0,56.409 4.44266667,42.3403333 13.328,29.281 C22.2133333,16.2216667 33.253,6.46133333 46.447,0 L48.87,4.847 C42.9529722,9.44418507 37.8940026,15.0498782 33.926,21.406 C29.618,28.1393333 26.5216667,36.4863333 24.637,46.447 L33.119,48.466 C42.5423333,50.622 49.8793333,54.5933333 55.13,60.38 C60.3329144,66.0422466 63.1536888,73.4917269 63.006,81.18 C63.006,89.796 60.1116667,96.796 54.323,102.18 C48.5860888,107.547221 40.9718948,110.448551 33.118,110.26 Z'%3E%3C/path%3E%3Cpath fill='#d8b684' d='M118.337,110.26 C108.374333,110.26 100.364,106.693333 94.306,99.56 C88.248,92.4266667 85.219,83.07 85.219,71.49 C85.219,56.412 89.6616667,42.3433333 98.547,29.284 C107.432333,16.2246667 118.472,6.46333333 131.666,0 L134.089,4.847 C128.171972,9.44418507 123.113003,15.0498782 119.145,21.406 C114.837,28.1393333 111.740667,36.4863333 109.856,46.447 L118.338,48.466 C127.762,50.622 135.099,54.5933333 140.349,60.38 C145.551914,66.0422466 148.372689,73.4917269 148.225,81.18 C148.225,89.796 145.330667,96.796 139.542,102.18 C133.805338,107.546976 126.191552,110.448287 118.338,110.26 L118.337,110.26 Z'%3E%3C/path%3E%3C/svg%3E");
        opacity: 0.75;
      }
    }

    .appetiser {
      &-wrapper {
        &:before {
          background: var(--color-earth);
        }
        &:after {
          border-color: var(--color-earth);
        }
      }

      .button {
        color: var(--color-earth);
        border-color: var(--color-earth);

        &:hover {
          background: var(--color-earth);
          border-color: var(--color-earth);
          color: #fff;
        }
      }

      &:after {
        background: var(--color-earth);
      }
    }

    .cta {
      .button {
        color: var(--color-earth);
        border-color: var(--color-earth);

        &:hover {
          background: var(--color-earth);
          border-color: var(--color-earth);
          color: #fff;
        }
      }
    }

    .faq {
      &-list,
      &-list-item-content__inner,
      &-list-item-header {
        border-color: var(--color-earth);
      }

      &-cta {
        &__button {
          color: var(--color-pink);
          border-color: var(--color-pink);

          &:hover {
            background: var(--color-pink);
            border-color: var(--color-pink);
            color: var(--color-earth);
          }
        }

        &:after {
          background: var(--color-earth);
        }
      }
    }
  }

  &-blue {
    /* Grid elements */
    .image,
    .video,
    .clinic-finder-cta {
      &:before {
        background: var(--color-blue);
      }
    }

    .quote {
      background: var(--color-blue);
      color: #fff;

      &:before {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 149 111' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#d8b684' d='M33.118,110.26 C23.1553333,110.26 15.145,106.693333 9.087,99.56 C3.029,92.4266667 0,83.069 0,71.487 C0,56.409 4.44266667,42.3403333 13.328,29.281 C22.2133333,16.2216667 33.253,6.46133333 46.447,0 L48.87,4.847 C42.9529722,9.44418507 37.8940026,15.0498782 33.926,21.406 C29.618,28.1393333 26.5216667,36.4863333 24.637,46.447 L33.119,48.466 C42.5423333,50.622 49.8793333,54.5933333 55.13,60.38 C60.3329144,66.0422466 63.1536888,73.4917269 63.006,81.18 C63.006,89.796 60.1116667,96.796 54.323,102.18 C48.5860888,107.547221 40.9718948,110.448551 33.118,110.26 Z'%3E%3C/path%3E%3Cpath fill='#d8b684' d='M118.337,110.26 C108.374333,110.26 100.364,106.693333 94.306,99.56 C88.248,92.4266667 85.219,83.07 85.219,71.49 C85.219,56.412 89.6616667,42.3433333 98.547,29.284 C107.432333,16.2246667 118.472,6.46333333 131.666,0 L134.089,4.847 C128.171972,9.44418507 123.113003,15.0498782 119.145,21.406 C114.837,28.1393333 111.740667,36.4863333 109.856,46.447 L118.338,48.466 C127.762,50.622 135.099,54.5933333 140.349,60.38 C145.551914,66.0422466 148.372689,73.4917269 148.225,81.18 C148.225,89.796 145.330667,96.796 139.542,102.18 C133.805338,107.546976 126.191552,110.448287 118.338,110.26 L118.337,110.26 Z'%3E%3C/path%3E%3C/svg%3E");
        opacity: 0.75;
      }
    }

    .appetiser {
      &-wrapper {
        &:before {
          background: var(--color-blue);
        }
        &:after {
          border-color: var(--color-blue);
        }
      }

      .button {
        color: var(--color-blue);
        border-color: var(--color-blue);

        &:hover {
          background: var(--color-blue);
          border-color: var(--color-blue);
          color: #fff;
        }
      }

      &:after {
        background: var(--color-blue);
      }
    }

    .before-after-slider {
      &:before,
      &:after {
        color: var(--color-blue);
      }
    }

    .cta {
      .button {
        color: var(--color-blue);
        border-color: var(--color-blue);

        &:hover {
          background: var(--color-blue);
          border-color: var(--color-blue);
          color: #fff;
        }
      }
    }

    .faq {
      &-list,
      &-list-item-content__inner,
      &-list-item-header {
        border-color: var(--color-blue);
      }

      &-cta {
        &__button {
          color: var(--color-pink);
          border-color: var(--color-pink);

          &:hover {
            background: var(--color-pink);
            border-color: var(--color-pink);
            color: var(--color-blue);
          }
        }

        &:after {
          background: var(--color-blue);
        }
      }
    }
  }

  &-mustard {
    /* Grid elements */
    .image,
    .video,
    .clinic-finder-cta {
      &:before {
        background: var(--color-mustard);
      }
    }

    .quote {
      background: var(--color-mustard);
      color: #fff;

      &:before {
        opacity: 0.75;
      }
    }

    .appetiser {
      &-wrapper {
        &:before {
          background: var(--color-mustard);
        }
        &:after {
          border-color: var(--color-mustard);
        }
      }

      .button {
        color: var(--color-mustard);
        border-color: var(--color-mustard);

        &:hover {
          background: var(--color-mustard);
          border-color: var(--color-mustard);
          color: #fff;
        }
      }

      &:after {
        background: var(--color-mustard);
      }
    }

    .before-after-slider {
      &:before,
      &:after {
        color: var(--color-mustard);
      }
    }

    .cta {
      .button {
        color: var(--color-mustard);
        border-color: var(--color-mustard);

        &:hover {
          background: var(--color-mustard);
          border-color: var(--color-mustard);
          color: #fff;
        }
      }
    }

    .faq {
      &-list,
      &-list-item-content__inner,
      &-list-item-header {
        border-color: var(--color-mustard);
      }

      &-cta {
        &:after {
          background: var(--color-mustard);
        }
      }
    }
  }
}
