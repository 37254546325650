/*
 * Molecule: Grid element - Clinic finder
 */

.grid-clinic-finder {
  position: relative;
  height: 70vh;

  @media (--viewport-md-min) {
    height: 90vh;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100vw;
    height: 70vh;

    @media (--viewport-md-min) {
      height: 90vh;
    }
  }
}
