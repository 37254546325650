/*
 * Page: Search results page
 */

.search-results {
  &-field {
    position: relative;
    z-index: 1;
    width: 100%;
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-primary);
    margin-bottom: 56px;
  }

  &-submit {
    min-width: auto;
    border: 0;
    background: none;
  }

  &-input {
    border: 0;
  }
}
