/*
 * Organism: backdrop
 */

.backdrop {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  background: #000;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s var(--transition);
  pointer-events: none;
  opacity: 0;

  &--show {
    opacity: 0.5;
    pointer-events: auto;
  }
}
