/*
 * Molecule: Grid element - Before After Slider
 */
/*! Flickity v2.2.1 https://flickity.metafizzy.co */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: height 0.5s var(--transition);
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-slider .image-slideshow-slide {
  list-style-type: none;
}

.before-after-slider {
  position: relative;
  margin: 0 calc(0 - var(--half-gutter-width)) 64px;
  width: calc(100% + (2 * var(--half-gutter-width)));

  @media (--viewport-sm-min) {
    margin: 0 0 64px;
    width: 100%;
  }

  &:before,
  &:after {
    display: block;
    position: absolute;
    z-index: 2;

    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    font-style: italic;
    font-size: 1.875rem; /* 30px */
    line-height: 2rem; /* 32px */
    color: var(--color-primary);
    opacity: 0.5;

    @media (--viewport-sm-min) {
      font-size: 3.25rem; /* 52px */
      line-height: 3.125rem; /* 50px */
    }
  }

  &:before {
    content: attr(data-label-before);
    top: 0;
    transform: translate3d(0, -50%, 0);
    left: 8px;

    @media (--viewport-sm-min) {
      left: 32px;
    }
  }

  &:after {
    content: attr(data-label-after);
    bottom: 0;
    transform: translate3d(0, 50%, 0);
    right: 8px;

    @media (--viewport-sm-min) {
      right: 32px;
    }
  }

  &-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    z-index: 1;
    transform: translateZ(0);
  }

  &__before {
    display: block;
    width: 100%;
    pointer-events: none;
    user-select: none;
  }

  &__after {
    display: block;
    max-width: none;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  &-handle {
    position: absolute;
    width: 56px;
    left: 56px;
    top: 50%;
    cursor: ew-resize;
    transform: translate3d(0, -50%, 0);
    z-index: 2;
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &-knob {
      display: block;
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 56px;
      height: 56px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 100%;

      &__left,
      &__right {
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        height: 12px;
        width: 12px;

        svg {
          min-height: 12px;
          min-width: 12px;
          height: 12px;
          width: 12px;
          fill: #fff;
        }
      }

      &__left {
        transform: translate3d(calc(-50% - 8px), -50%, 0) rotate(90deg);
      }
      &__right {
        transform: translate3d(calc(-50% + 8px), -50%, 0) rotate(-90deg);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 1px;
      height: 100%;
      background: #fff;
    }
  }
}
